<template>
  <div class="mobNav">
    <div class="mobNav__content">
      <div class="mobNav__items">
        <div class="mobNav__items-title">
          <span>Меню</span>
        </div>
        <div class="category">
          <div class="category__items tt">
            <div class="category__items-title tt__ext">
              <h1 class="f-14">Все товары</h1>
            </div>
            <ul class="category__items-links">
              <li>
                <a>
                  <p>Исламские карты</p>

                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#9BA1A8"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="category__items tt">
            <div class="category__items-title tt__ext">
              <h1 class="f-14">Все товары</h1>
            </div>
            <ul class="category__items-links">
              <li>
                <a>
                  <p>Исламские карты</p>

                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="#9BA1A8"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mobNav__items-social">
          <div class="mobNav__items-social-title">
            <span>Мы в соц. сетях</span>
          </div>
          <div class="mobNav__items-social-list">
            <a></a>
            <a></a>
            <a></a>
            <a></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.category {
  display: grid;
  gap: 5px;
  &__items {
    padding: 16px;
    background: #f5f6f6;
    border-radius: 16px;

    &-links {
      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        a {
          color: black;
          width: 100%;
          display: inline-block;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          width: 100%;

          p {
            display: flex;
            align-items: center;
            align-content: center;
            gap: 16px;

            img {
              width: 100%;
              width: 40px;
              height: 40px;
              background: #ebecee;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          svg {
            align-content: flex-end;
          }
        }
      }
    }
  }
}
.tt {
  display: grid;
  gap: 12px;

  &__ext {
    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #80878e;
    }
  }
}
.mobNav {
  &__items {
    &-title {
      text-align: center;
      padding: 20px 0;
      span {
        font-size: 18px;
        color: #212a37;
        font-weight: 600;
      }
    }
    &-social {
      &-title {
        margin: 20px 0 5px 0;
        text-align: center;
        span {
          font-size: 16px;
          color: #212a37;
          font-weight: 500;
          opacity: 0.4;
        }
      }
      &-list {
        display: flex;
        flex-direction: row;
        gap: 4px;
        a {
          height: 49px;
          width: 100%;
          background-color: #f5f6f6;
          &:first-child {
            border-radius: 16px 0 0 16px;
          }
          &:last-child {
            border-radius: 0 16px 16px 0;
          }
        }
      }
    }
  }
}
</style>
