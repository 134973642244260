<template>
  <div class="header__item" @mouseleave="isOpen = false">
    <router-link
      class="header__link font-14 uppercase"
      :to="{ name: mainRoute }"
      @mouseover="isOpen = true"
      :class="{ active: isOpen }"
      ><p>{{ title }}</p><img :style="isOpen ? 'transition: all .5s ease-out; transform: rotate(180deg); filter: brightness(0) saturate(100%) invert(68%) sepia(47%) saturate(7498%) hue-rotate(198deg) brightness(87%) contrast(91%);' : ''" src="@/assets/images/svg/down.svg" /></router-link
    >
    <transition name="fade" appear>
      <nav class="sub-menu" v-if="isOpen">
        <ul class="sub-menu__list">
          <li v-for="(link, i) in links" :key="i">
            <a :href="link.route" class="sub-menu__link">
              <span><img src="" alt="" /></span>
              {{ link.title }}</a
            >
          </li>
        </ul>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: ["title", "mainRoute", "links"],
  // {
  //   title: {
  //     type: String,
  //     required: true,
  //   },
  //   mainRoute: {
  //     type: String,
  //     required: true,
  //   },
  //   links: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss">
.header {
  &__item {
    .active{
      color: #417cd6 !important;
    }
    a {
      
      font-weight: 400;
      font-size: 18px;
      color: #000000;

      &:hover, &:focus, .active {
        color: #417cd6 !important;
        img{
          filter: brightness(0) saturate(100%) invert(68%) sepia(47%) saturate(7498%) hue-rotate(198deg) brightness(87%) contrast(91%);
        }
      }
    }
  }
}
.sub-menu {
  min-width: 255px;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 16px 0 0 0;
  z-index: 999999!important;
  &__list {
    border-radius: 12px;
    border: 4px solid #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex-direction: column;
    align-items: flex-start!important;
  background: #fff;
    li:not(:last-child) {
      margin: 0 0 16px 0;
    }
  }
  &__link {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 118.75%;
    // color: #272724;
    color: rgba(32, 32, 32, 0.8);
    transition: color 0.3s ease 0s;
    white-space: nowrap;

    &:hover {
      font-weight: 500;
      color: #3252c6;
      border: unset !important;
    }
  }
}
.fade-enter-active,
.fade-enter-leave {
  transition: all 0.5s ease-out;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}
</style>
