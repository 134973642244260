<template>
  <div v-if="HomeData?.banner" class="banner">
    <div class="banner__content container">
      <div class="banner__items">
        <div class="banner__items-img">
            <img :src="HomeData?.banner?.image ? HomeData?.banner?.image : '@/assets/images/png/hyundai_santa_fe.png'" alt="">
        </div>
        <div class="banner__items-shariat">
          <div class="banner__items-shariat-list" v-for="(item,idx) in HomeData?.banner?.bannerLists" :key="idx">
            <span
              ><svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9163 1.875H10.333C5.50052 1.875 1.58301 5.79251 1.58301 10.625V10.625C1.58301 15.4575 5.50052 19.375 10.333 19.375V19.375C15.1655 19.375 19.083 15.4575 19.083 10.625V10.3233M5.66634 8.875L9.74968 13.5417L19.083 3.625"
                  stroke="#357BF6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <h5 class="f-20">{{ item.title }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['HomeData'],
    data: () => ({
       
    })
};
</script>

<style></style>
