<template>
  <div class="header">
    <div class="header__content">
      <div class="header__items">
        <div class="header__items-logo">
          <img class="m_none" src="@/assets/images/png/Logov2.png" alt="" />
          <img
            class="d_none"
            src="@/assets/images/png/Logo_mobile.png"
            alt=""
          />
        </div>
        <div class="header__items-nav m_none">
          <ul>
            <li>
              <Dropdown
                :title="'О нас'"
                :links="aboutusLinks"
              ></Dropdown>
            </li>
            <li>
              <a :href="`https://tayyab.kz/${$i18n.locale}/articles`">
                <p class="f-18">Tayyab Journal</p>
              </a>
            </li>
            <li>
              <a :href="`https://tayyab.kz/store`">
                <p class="f-18">Store</p>
              </a>
            </li>
            <li>
              <Dropdown
                :title="'Акции и скидки'"
                :links="salesLinks"
              ></Dropdown>
            </li>
            <li>
              <Dropdown
                :title="'Другие продукты'"
                :links="otherLinks"
              ></Dropdown>
            </li>
          </ul>
        </div>
        <div class="header__items-lang">
          <button class="header__items-lang-btn" @click="showInfo(open[0], 0)">
            <p v-if="selectedLang" class="f-18">
              {{ selectedLang.name }}
            </p>
            <span v-if="open[0]">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7L7 1L13 7"
                  stroke="#858585"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span v-else
              ><svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L7 7L1 1"
                  stroke="#858585"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          <button @click="openMobNav()" class="d_none header__items-lang-btnNav"><img src="@/assets/images/svg/Menu_icon.svg" /></button>
          <dropdownVue
            class="header__items-login-dropdown-list"
            :open="open[0]"
            :class="{ databody__active: open[0] }"
          >
            <div
              class="header__items-login-dropdown-list-all font-16"
              v-for="(link, idx) in langLinks"
              :key="idx"
            >
              <button @click.prevent="changeLang(link)">
                <p class="f-18">{{ link.name }}</p>
              </button>
            </div>
          </dropdownVue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownVue from "@/components/dropdown";
import Dropdown from "@/components/dropdown/DropdownMenu.vue";
import dropdown from "@/components/dropdown/dropdown.vue";
export default {
  components: {
    dropdownVue,
    Dropdown,
    dropdown,
  },
  data() {
    const lang = localStorage.getItem("lang") || "kz";
    return {
      selected: lang,
      langLinks: [
        {
          name: "Қаз",
          value: "kz",
        },
        {
          name: "Eng",
          value: "en",
        },
        {
          name: "Рус",
          value: "ru",
        },
      ],
      open: [false],
      aboutusLinks: [
        { title: "Исламские карты", route: "https://tayyab.kz/cards" },
        { title: "Соответствие Шариату", route: "https://tayyab.kz/shariyah" },
        { title: "Иман", route: "https://tayyab.kz/iman" },
        { title: "Зарплатная карта", route: "https://tayyab.kz/salary-card" },
        { title: "О компании", route: "https://tayyab.kz/about" },
        { title: "FAQ", route: "https://tayyab.kz/faq" },
      ],
      salesLinks: [
        { title: "Акции", route: "https://tayyab.kz/specials" },
        { title: "Скидки от партнеров", route: "https://tayyab.kz/sale" },
      ],
      otherLinks: [
        {
          title: "Халал рассрочка на автомобили",
          route: "https://auto.tayyab.kz",
        },
      ],
    };
  },
  computed: {
    selectedLang() {
      return this.langLinks.find((item) => item.value === this.selected);
    },
  },
  methods: {
    openMobNav(){
      this.$store.commit('TOGGLE_MOBILE_NAV')
    },
    changeLang(value) {
      localStorage.setItem("lang", value.value);
      window.location.reload();
    },
    showInfo(value, index) {
      value = !value;
      this.open[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
  },
};
</script>

<style></style>
