<template>
  <div>
    <h2>Калькулятор кредита аннуитет</h2>
    <label for="carPrice">Стоимость автомобиля:</label>
    <input type="number" id="carPrice" v-model.number="carPrice">
    <br>
    <label for="term">Срок кредита (в годах):</label>
    <input type="number" id="term" v-model.number="term">
    <br>
    <button @click="calculate">Рассчитать</button>
    <br>
    <div v-if="showResult">
      <p>Первоначальный взнос: {{ downPayment }} ₸</p>
      <p>Ежемесячный платеж: {{ monthlyPayment }} ₸</p>
      <p>Максимальная сумма рассрочки: {{ maxInstallment }} ₸</p>
    </div>
    <div v-if="showError">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carPrice: null,
      term: null,
      downPayment: null,
      maxInstallment: null,
      monthlyPayment: null,
      showError: false,
      showResult: false,
      errorMessage: null
    };
  },
  methods: {
    calculate() {
      this.showError = false;

      if (this.carPrice > 60000000) {
        this.showError = true;
        this.errorMessage = "Стоимость автомобиля не может превышать 60 000 000 ₸";
        return;
      }

      if (this.term < 1 || this.term > 7) {
        this.showError = true;
        this.errorMessage = "Срок кредита должен быть от 1 до 7 лет";
        return;
      }

      const maxDownPayment = this.carPrice * 0.2;

      if (this.downPayment < maxDownPayment) {
        this.showError = true;
        this.errorMessage = `Первоначальный взнос должен быть не менее ${maxDownPayment} ₸`;
        return;
      }

      let creditAmount = this.carPrice - this.downPayment;

      if (creditAmount > 15000000) {
        creditAmount = 15000000;
        this.downPayment = this.carPrice - creditAmount;
      }

      this.maxInstallment = creditAmount;
      const monthlyRate = 0.36 / 12;
      const termInMonths = this.term * 12;
      const rateExponent = Math.pow(1 + monthlyRate, termInMonths);

      this.monthlyPayment = ((creditAmount * monthlyRate * rateExponent) / (rateExponent - 1)).toFixed(2);

      this.showResult = true;
    }
  }
};
</script>