<template>
  <div class="faqs" v-if="HomeData">
    <div class="faqs__content container">
      <div class="faqs__items">
        <div class="faqs__items-title">
          <h1 class="f-40">{{ $t('label.FAQ') }}</h1>
        </div>
        <div class="faqs__items-dropdown">
          <div class="faqs__items-dropdown-list">
            <FAQs
              v-for="(faq, i) in HomeData.questions"
              :faq="faq"
              :index="i"
              :key="i"
              :open="faq.open"
              @toggleOpen="toggleOpen"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQs from "@/components/accordion/FAQ.vue";
export default {
  props: ['HomeData'],
  components: {
    FAQs,
  },
  data: () => ({
    open: false,
    question: [],
    faqs: [],
  }),
  methods: {
    toggleOpen: function (index) {
      this.HomeData.questions = this.HomeData.questions.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      });
    },
  },
};
</script>

<style></style>
