<template>
  <div class="confAlert">
    <div v-if="modalText" class="confAlert__items">
      <div class="confAlert__items-icon">
        <img v-if="modalText.success" src="@/assets/images/svg/Success.svg" />
      </div>
      <div class="confAlert__items-title">
        <h2 class="f-20" v-html="modalText.cTitle"></h2>
      </div>
      <!-- <div class="confAlert__items-btns">
           <button class="font-16 btn_blue" @click="$emit('confirm')" v-if="modalText.yes">{{ modalText.yes }}</button>
            <button class="font-16 btn_link_red" @click="$emit('decline')" v-if="modalText.no">{{ modalText.no }}</button>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalText"],
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.confAlert{
    &__items{
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        gap: 16px;
        &-title{
            margin: 0 40px 40px 40px;
            text-align: center;
        }
    }
}
</style>
