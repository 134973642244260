<template>
  <div class="footer" v-if="footerData">
    <div class="footer__content container">
      <div class="footer__items">
        <div class="footer__items-top">
          <div class="footer__items-top-left">
            <button
              class="btn_black f-14"
              @click="toDownload(item)"
              v-for="(item, idx) in socialLinks"
              :key="idx"
            >
              {{ item.title }}
            </button>
          </div>
          <div class="footer__items-top-right">
            <div class="footer__items-top-right-row">
              <div class="footer__items-top-right-row-col1">
                <div class="footer__items-top-right-row-col1-social">
                  <a v-for="(item,idx) in footerData?.socials" :key="idx" :href="item.link" target="_blank" rel="noopener noreferrer">
                    <img :src="item.image" />
                  </a>
                 
                </div>
                <div class="footer__items-top-right-row-col1-support">
                  <span class="f-15"> {{ $t('label.techPod') }}</span
                  ><a
                    class="f-15"
                    :href="'mailto:'+ footerData?.contact?.email"
                    rel="noopener noreferrer"
                  >
                    {{ footerData?.contact?.email }}
                  </a>
                </div>
              </div>
              <div class="footer__items-top-right-row-col2">
                <div class="footer__items-top-right-row-col2-phone">
                  <img src="@/assets/images/svg/tel.svg" alt="" />
                  <div class="footer__items-top-right-row-col2-phone-text">
                    <a class="f-18" :href='footerData?.contact?.whatsappLink'
                       v-html="footerData?.contact?.whatsapp"></a
                    >
                    <p class="f-12">{{ $t('label.onlyMessage') }}</p>
                  </div>
                </div>
                <div class="footer__items-top-right-row-col2-links">
                  <a
                    :href="footerData?.documents?.certificate"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="f-15"
                  >
                  {{ $t('buttons.certificate') }}
                  </a>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer__items-bottom">
          <div class="footer__items-bottom-offer">
            <a
              :href="footerData?.documents?.police"
              target="_blank"
              rel="noopener noreferrer"
              class="f-12"
              > {{ $t('buttons.politic_confedential') }}</a
            >
            <a
              :href="footerData?.documents?.public_offer"
              target="_blank"
              rel="noopener noreferrer"
              class="f-12"
              > {{ $t('buttons.public_offer') }}</a
            >
          </div>
          <div class="footer__items-bottom-info">
            <p class="f-12" v-html="footerData?.contact?.address">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['footerData'],
  data: () => ({
    socialLinks: [
      {
        title: "Google Play",
        link: true,
        icon: "",
        route: "https://play.google.com/store/apps/details?id=co.tayyab.app",
      },
      {
        title: "App store",
        link: true,
        icon: "",
        route: "https://apps.apple.com/us/app/tayyab/id1562092088",
      },
      {
        title: "Стать партнером",
        link: false,
        icon: "",
        route: "modal",
      },
    ],
  }),
  created() {
    this.socialLinks[2].title = this.$t('buttons.getPartnre')
  },
  methods: {
    toDownload(value) {
      if (value.link === true) {
        window.location.assign(value.route);
      } else {
        console.log("asdasd", value);
      }
    },
  },
};
</script>

<style></style>
