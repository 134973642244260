<template>
  <div class="textAlert">
    <div v-if="modalText" class="textAlert__items">
      <div class="textAlert__items-icon">
        <img v-if="modalText.success" src="@/assets/images/svg/Success.svg" />
      </div>
      <div v-if="modalText.cTitle" class="textAlert__items-title">
        <h2 class="f-20" v-html="modalText.cTitle"></h2>
      </div>
      <div v-if="modalText.cText" class="textAlert__items-text">
        <h2 class="f-20" v-html="modalText.cText"></h2>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  props: ["modalText"],
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.textAlert {
  // padding: 0 16px;
  text-align: center;
  @media (max-width: 576px) {
    margin-top: 60px;
  }
.closeBtn{
  margin-top: 10px;
  padding: 10px;
  color: #357BF6;
}
  &__items {
    // max-width: 80vw;
    width: 80vw;
    max-height: 70vh;
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-content: center;
    // align-items: center;
    @media (max-width: 576px) {
      width: 100%;
      height: 100%;
      max-height: unset;
      overflow: hidden;
    }
    gap: 16px;
    &-text {
      width: 100%;
      text-align: justify;
      max-height: 88vh;
    overflow-y: auto;
    }
  }
}
</style>
